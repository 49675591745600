declare global {
  interface Window {
    cometTokenOverride: string | null
    cometFingerprintOverride: string | null
    cometFingerprint: () => Promise<string>
    cometToken: () => string
    comet: CometFunc
  }
  type CometFunc = (event: string, jsonData?: any | null) => void
}

export default defineNuxtPlugin({
  parallel: true,
  enforce: 'post',
  setup(nuxtApp) {
    const cometFuncState: Ref<CometFunc | undefined> = useState('comet', () => undefined)

    // Load script asynchronously
    const loadScript = () => {
      const scriptEl = document.createElement('script')
      scriptEl.src = 'https://t.cometlytrack.com/e?uid=a9509a-3377699736000086-594d9b-s'
      scriptEl.async = true // Add async attribute
      scriptEl.onload = () => {
        cometFuncState.value = window.comet
        window.comet('init')
      }
      document.head.appendChild(scriptEl)
    }

    // Defer script loading
    if (import.meta.client) {
      nuxtApp.hook('app:mounted', () => {
        setTimeout(loadScript, 0)
      })
    }
  },
})
