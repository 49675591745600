import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const config = useRuntimeConfig()

    if (!config.public.sentryDsn) {
      console.warn('Sentry DSN not set, skipping Sentry initialization')
      return
    }

    if (!config.public.sentryEnabled) {
      console.warn('Sentry not enabled, skipping Sentry initialization')
      return
    }

    Sentry.init({
      debug: false,
      enabled: config.public.sentryEnabled,
      dsn: config.public.sentryDsn,
      release: config.public.sentryRelease,
      environment: config.public.sentryEnvironment,
      app: nuxtApp.vueApp,
      integrations: [],
      hooks: ['unmount', 'mount', 'update'],
    })

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    }
  },
})
